<template>
  <div>
    <!-- <v-alert prominent type="success" v-if="(formObj.configuration && formObj.configuration.limitresponse) ? !userDetails : true">
      <v-row align="center">
        <v-col class="grow">
          Requires Sign in
        </v-col>
        <v-col class="shrink">
          <v-btn @click="$router.push('/login')">Login</v-btn>
        </v-col>
      </v-row>
    </v-alert> -->
    <v-alert prominent type="success" v-if="showSuccess">
      <v-row align="center">
        <v-col class="grow">
          Your response submitted successfully ...
        </v-col>
        <v-col class="shrink">
          <v-btn @click="resetForm">Submit Another</v-btn>
        </v-col>
      </v-row>
    </v-alert>
    <template v-else>
      <form-renderer v-if="formId" ref="form" :formId="formId" :modelObj.sync="modelObj" />
      <v-divider></v-divider>
      <v-layout class="d-flex justify-center">
        <v-btn @click="saveOrUpdateHandler" color="success" tile>Save</v-btn>
      </v-layout>
    </template>
  </div>
</template>

<script>
import CryptoJS from 'crypto-js'
import { api } from '@/plugins/axios'
export default {
  components: {
    'form-renderer': () => import('@/components/FormRender.vue'),
  },
  data () {
    return {
      showSuccess: false,
      formId: '',
      modelObj: {
        formdata: {},
      }
    }
  },
  mounted () {
    this.getFormInfo()
  },
  methods: {
    getFormInfo () {
      this.$_execute(`get`, `openforms/get-form/${this.$route.params.openId}`).then(({ data }) => {
        api.defaults.headers['x-openapi-token'] = data.token
        this.formId = data.formId
      })
    },
    resetForm () {
      this.modelObj = { formdata: {}, formid: this.formId }
      this.showSuccess = false
    },
    encrypt (src) {
      return CryptoJS.AES.encrypt(src, process.env.VUE_APP_SECRETE_TICKET).toString()
    },
    decrypt (src) {
      const bytes = CryptoJS.AES.decrypt(src, process.env.VUE_APP_SECRETE_TICKET)
      return bytes.toString(CryptoJS.enc.Utf8)
    },
    saveOrUpdateHandler () {
      const payload = { ...this.modelObj }
      payload.formid = this.formId
      this.$_execute(`post`, `/form-result/${this.formId}`, payload).then(() => {
        this.showSuccess = true
      })
    }
  },
  beforeDestroy () {
    api.defaults.headers['x-openform-token'] = undefined
  }
}
</script>
